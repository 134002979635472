<template>
  <div class="m-roles-list">
    <b-notification
      v-if="deleteError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('settings.roleDeleteError') }}
    </b-notification>
    <b-table :data="roles" :paginated="true" :per-page="20" :loading="loading" default-sort="name">
      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'settings-role-edit', params: { roleId: props.row._id } }">{{
          props.row._id
        }}</router-link>
      </b-table-column>

      <b-table-column field="name" :label="$t('settings.roleName')" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="description" :label="$t('settings.roleDescription')" v-slot="props">
        {{ props.row.description }}
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button type="is-danger" icon-right="trash" @click="confirmDelete(props.row._id)" />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'RolesList',
  components: { NoDataToList },
  data() {
    return {
      isMenuActive: true,
      loading: false,
      loadingError: false,
      deleteError: false
    };
  },
  computed: {
    roles() {
      if (this.$store.state.system.registries) {
        return this.$store.state.system.registries.roles;
      }
      return [];
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.$store
      .dispatch(namespaces.SYSTEM + actions.FETCH_REGISTRIES)
      .catch(error => {
        this.loadingError = true;
        logger.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    confirmDelete(roleId) {
      this.$buefy.dialog.confirm({
        message: this.$t('settings.roleConfirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.SYSTEM + actions.DELETE_ROLE, roleId)
            .then(() => {
              this.deleteError = false;
            })
            .catch(error => {
              this.deleteError = true;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
